'use strict';

function parseURL($string) {
	var imgRegex = /\.(?:jpe?g|gif|png)$/gim,
		videoRegex = /((?:file|ftp|https?):\/\/(?:[^\s]|)+(?:.mov|mp4|ogg|mpg))(?:\?*(?:[^\s])*)*/gim,
		urlRegex = /(((https?|ftp|file):)?\/\/[-a-zA-Z0-9+&@#\/%?=~_|!:,.;]*[-a-zA-Z0-9+&@?#\/%=~_|])/gim,
		exp = urlRegex;

	return $string.replace(exp, function(match) {
		var result = '';

		imgRegex.lastIndex = 0;

		if (imgRegex.test(match)) {
			result = '<a href="#" class="fjs-image-preview" data-url="' + match + '"><img src="' + match + '" class="imagified" /></a>';
		} else if (videoRegex.test(match)) {
			if (match.indexOf('dropbox.com') !== -1) {
				match = match.split('?dl=0').join('');
			}

			result = '<video width="100%" height="auto" controls> <source src="' + match + '" type="video/mp4"> </video>';
		} else {
			result = '<a href="' + match + '" class="linkified" target="_blank">' + match + '</a>';
		}

		return result;
	});
}

function makeRichText(inputText) {
	inputText = parseURL(inputText);

	return inputText;
}

module.exports = {
	'makeRichText': makeRichText
};
