'use strict';

var QueryString = require('./querystring'),
	Logger = null;

Logger = (function() {
	var triggeredKeys = null,
		registeredKeys = [],
		_log = null;

	init();

	function init() {
		triggeredKeys = getTriggeredKeys();
		_log = register('Logger', 'list');
	}

	function updateList(key) {
		if (key !== 'list') {
			registeredKeys.push(key);

			if (_log !== null) {
				_log('Registered Keys', registeredKeys);
			}
		}
	}

	function register(label, trigger) {
		var newConfig = {
			'label': label,
			'trigger': trigger
		};

		return validateRegistration(newConfig);
	}

	function validateRegistration(newConfig) {
		var loggerFunction = function() { return false; },  // eslint-disable-line
			config = createValidConfig(newConfig);

		if (config !== null) {
			updateList(config.trigger);
			loggerFunction = createLogFunction(config);
		}

		return loggerFunction;
	}

	function createLogFunction(config) {
		var writeLogs = checkForLogParameter(config.trigger);

		config.writeLogs = writeLogs;

		return log.bind(this, config);
	}

	function log(config) {
		var args;

		if (config.writeLogs === true && console && typeof console.log !== 'undefined') { // eslint-disable-line no-console
			args = Array.prototype.slice.call(arguments);
			args.shift(); // get rid of the config argument; we don't want to log that
			args.unshift('[' + config.label + ']'); // add the label prefix in the logline

			if (typeof console.log.apply !== 'undefined') { // eslint-disable-line no-console
				console.log.apply(console, args); // eslint-disable-line no-console
			} else {
				console.log(args); // eslint-disable-line no-console
			}
		}
	}

	function createValidConfig(newConfig) {
		var config = {
			'label': '',
			'trigger': ''
		};

		if (typeof newConfig !== 'undefined') {
			copyConfigValue('label', newConfig, config);
			copyConfigValue('trigger', newConfig, config);
		}

		return config;
	}

	function copyConfigValue(key, fromObject, toObject) {
		if (fromObject !== null && typeof fromObject[key] !== 'undefined') {
			toObject[key] = fromObject[key];
		} else {
			toObject = null;
		}
	}

	function getTriggeredKeys() {
		var keys = QueryString.getValue('logs');

		if (keys !== null) {
			keys = keys.split(',');
		} else {
			keys = [];
		}

		return keys;
	}

	function checkForLogParameter(target) {
		var result = false;

		if (triggeredKeys.indexOf('all') !== -1) return true;

		if (triggeredKeys.indexOf(target) !== -1) {
			result = true;
		}

		return result;
	}

	return {
		'register': register
	};
}());

module.exports = Logger;
