'use strict';

var Logger = require('./logger'),
	bean = require('../vendors/bean'),
	bonzo = require('../vendors/bonzo'),
	tools = require('./tools'),
	queryString = require('./querystring'),
	Extender = null;

 Extender = function($) {
	$.tools = tools;
	$.queryString = queryString;

	bonzo.aug({
		'wrap': function(newElement) {
			this.before(newElement);
			$(newElement).append(this);
		}
	});

	/**
	 * $.createLogger(tag, trigger) to create a logger that can then be used to log to the console
	 *
	 * @param {String} tag
	 * @param {String} trigger
	 * @return {Function}
	 */
	$.createLogger = function(tag, trigger) {
		return Logger.register(tag, trigger);
	};

	$.merge = function(slave, master) {
		var result = {},
			key;

		for (key in slave) {
			if (slave.hasOwnProperty(key)) {
				result[key] = slave[key];
			}
		}

		for (key in master) {
			if (master.hasOwnProperty(key)) {
				if (typeof master[key] === 'object') {
					result[key] = $.merge(slave[key], master[key]);
				} else {
					result[key] = master[key];
				}
			}
		}

		return result;
	};

	$.parseJSON = function(data) {
		var result = null;

		try {
			result = JSON.parse(data);
		} catch (e) {
			// nothing happens; it's broken, yo!
		}

		return result;
	};

	$.ready = function(callback) {
		if (document.readyState === 'complete' || document.readyState === 'interactive') {
			callback();
		} else {
			bean.add(document, 'DOMContentLoaded', callback);
		}
	};

	$.complete = function(callback) {
		window.addEventListener('load', callback);
	};

	$.viewport = function() {
		return bonzo.viewport();
	};

	// $.cookie = (function() {
	// 	function get() {
	// 		return jsCookie.get.apply(this, arguments);
	// 	}

	// 	function remove() {
	// 		return jsCookie.remove.apply(this, arguments);
	// 	}

	// 	function set() {
	// 		return jsCookie.set.apply(this, arguments);
	// 	}

	// 	return {
	// 		'get': get,
	// 		'remove': remove,
	// 		'set': set
	// 	};
	// }());

	return $;
};

module.exports = Extender;
