'use strict';

var QueryString = {
	'initialized': false,
	'queryString': null,
	'values': {},
	'init': function() {
		var vars, pair, key, value, i;

		if (this.initialized !== true) {
			this.queryString = this.getRaw().substring(1);

			if (this.queryString.length !== 0) {
				vars = this.queryString.split('&');

				for (i = 0; i < vars.length; i++) {
					pair = vars[i].split('=');
					key = pair[0];
					value = pair[1];

					if (typeof value !== 'undefined') {
						key = key.toLowerCase();
						value = unescape(value);

						this.values[key] = value;
					}
				}
			}

			this.initialized = true;
		}
	},
	'get': function() {
		this.init();

		return this.queryString;
	},
	'addValue': function(key, value) {
		this.init();
		this.values[key] = value;
	},
	'getValue': function(key, defaultValue) {
		var value = null;

		if (typeof defaultValue !== 'undefined') {
			value = defaultValue;
		}

		this.init();

		key = key.toLowerCase();
		if (this.values.hasOwnProperty(key)) {
			value = this.values[key];
		}

		return value;
	},
	'setValue': function(key, value) {
		this.init();

		key = key.toLowerCase();
		this.values[key] = value;
	},
	'getRaw': function() {
		return window.location.search;
	}
};

module.exports = QueryString;
